.animate-gradient {
	animation-name: gradient;
	animation-duration: 8s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	background: linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
	background-size: 400% 400%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
